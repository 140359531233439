.Sidebar {
    position: fixed;
    top: 10%;
    right: 0;
    width: 60px;
    height: 30%;
    background-color: lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.Sidebar button {
    background: none;
    border: none;
    color: white;
    margin-bottom: 20px;
    cursor: pointer;
}

.Sidebar button:hover {
    color: #ddd;
}