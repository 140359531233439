.carouselImage {

}

.carouselDiv {
    width: auto;
}

.image {
    height: 355px;
}

.containerPdf {
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
    justify-content: center !important;
    background-color: #FFFFFF;
    border-radius: .75rem;
    box-shadow: var(--box-shadow);
    height: 300px !important;
}

.aContainerPdf{
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
    justify-content: center !important;
}