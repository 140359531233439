.InsertedFragmentToEdit {
  margin-bottom: .25rem;
}

.InsertedFragment {
  background-color: var(--secondary-light);
  display: flex;
  border-radius: .5rem;
  margin-bottom: .25rem;
  position: relative;
}

.DragIconContainer {
  background-color: #ebeced;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: .5rem;
  border-top-left-radius: .5rem;
}

.InsertedFragmentContainer {
  width: calc(95% - 3rem - 3.25rem - 0.5rem);
  margin-top: .45rem;
  margin-bottom: .45rem;
  margin-left: .5rem;
}

.InsertedFragmentTitle {
  margin: 0;
  font-size: smaller;
  font-weight: bold;
  color: var(--text-light);
  text-transform: uppercase;
}

.InsertedFragmentContent {
  margin: 0;
  font-size: smaller;
  color: var(--text);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.EditDeleteIconContainer {
  width: 3.25rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom-right-radius: .5rem;
  border-top-right-radius: .5rem;
}

.EditDeleteIconContainer img {
  width: 1.25rem;
  cursor: pointer;
}

.EditDeleteIconContainer {
  display: flex;
  align-items: center;
}

.EditDeleteIconContainer input[type="checkbox"] {
  margin-right: 10px;
}