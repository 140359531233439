.Wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
}

.ContentContainer {
  width: 100%;
  margin-top: 5rem;
  padding-bottom: 5rem;
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.ContentContainer::-webkit-scrollbar {
  display: none;
}

.FragmentContainer {
}

.FragmentContainer:not(:first-child) {
  margin-top: 2rem;
}

.FragmentTitle {
  font-size: larger;
  color: var(--text);
  font-weight: bold;
  margin: .5rem 0;
  padding-left: 1rem;
  text-transform: capitalize;
  display: flex;

}

.SourcesContainer {
  display: flex;
  flex-wrap: wrap;
}

.SourceBox {
  background-color: #FFFFFF;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  margin: .5rem 1rem;
  width: 20%;
}

.SourceBoxLabel {
  margin: 0 0 .5rem 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: smaller;
  color: var(--text-light);
}

.SourceBoxText {
  line-height: 1.1em;
  margin: 0 0 .5rem 0;
  overflow: hidden;
  font-size: small;
}

.SourceBoxMediaRow {
  display: flex;
  margin-top: auto;
}

.SourceBoxMediaContainer {
  width: 150px;
  margin-left: 1rem;
  cursor: pointer;
}

.SourceBoxMediaContainer:first-child {
  margin-left: 0;
}

.SourceBoxMediaImage {
  object-fit: cover;
  border: 1px solid var(--primary);
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.PopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PopupBoxContainer {
  display: flex;
  background-color: #d0d0d0;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
}

.PopupImage {
  height: 50vh;
}

.PopupInfoColumn {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.ClosePopupContainer {
  display: flex;
}

.ClosePopupIcon {
  cursor: pointer;
  margin: 0 0 0 auto;
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary);
}

.PopupInfoContainer {
  margin-top: auto;
}

.PopupInfoRow {
  display: flex;
}

.PopupInfoRow:not(:first-child) {
  margin-top: 1rem;
}

.PopupInfoLabel {
  margin: 0 .5rem 0 0;
  color: var(--primary);
  font-weight: bold;
}

.PopupInfoText {
  margin: 0;
  color: var(--text);
  padding-bottom: 15px;
}

.PopupTable {
  border-collapse: collapse;
  width: 100%;
}

.PopupTable th, .PopupTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.PopupTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #AAAAAA;
  color: white;
}
