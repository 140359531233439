.BtnContainer {
  display: grid;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.Button {
  margin: 0;
  padding: .75rem 1.25rem;
  border-radius: 2.5rem;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  font-weight: bold;
  font-size: smaller;
}

.ActiveBtn {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 25%;
  top: 50%;
  height: calc(100% - 0.5rem);
  border-radius: 5rem;
  transition: left .2s ease-in-out;
}


.ButtonWrapper {
  position: relative;
}

.AdditionalButtons {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AdditionalButton {
  background-color: #fff;
  color: #000;
  padding: 0.5rem;
  margin: 0 0.2rem;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: relative;
}

.AdditionalButton:hover .ButtonLabel {
  display: block;
}

.Icon {
  width: 24px;
  height: 24px;
}

.ButtonLabel {
  display: none;
  position: absolute;
  bottom: -1.5rem;
  background-color: #fff;
  padding: 0.2rem 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  white-space: nowrap;
}