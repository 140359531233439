
.PopupInfoContainer {
    margin-top: auto;
}

.ImageAndTitleContainer {
    display: flex;
    flex-direction: column;
}

.ImageAndTitleContainer h2 {
    align-self: center;
}

.PopupInfoRow {
    display: flex;
}

.PopupInfoRow:not(:first-child) {
    margin-top: 1rem;
}

.PopupInfoLabel {
    margin: 0 .5rem 0 0;
    color: var(--primary);
    font-weight: bold;
}

.PopupInfoText {
    margin: 0;
    color: var(--text);
    padding-bottom: 15px;
}

.PopupTable {
    border-collapse: collapse;
    width: 100%;
}

.PopupTable th, .PopupTable td {
    border: 1px solid #ddd;
    padding: 8px;
}

.PopupTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #AAAAAA;
    color: white;
}
.PopupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.PopupBoxContainer {
    display: flex;
    background-color: #d0d0d0;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
}

.PopupImage {
    max-width: 100%;
    max-height: 50vh;
    width: auto;
    height: auto;
    display: block;
}

.PopupInfoColumn {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.ClosePopupContainer {
    display: flex;
}

.ClosePopupIcon {
    cursor: pointer;
    margin: 0 0 0 auto;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary);
}

.image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
}