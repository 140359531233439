.InsertedEntitiesType:not(:last-child) {
  margin-bottom: 1.5rem;
}
  
.InsertedEntityTitle {
  font-size: smaller;
  color: var(--text-light);
  margin: 0;
  text-transform: capitalize;
}
  


.InsertedEntityText {
  margin: 0 auto 0 0;
  font-size: smaller;
  font-weight: bold;
  color: var(--text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InsertedEntityText.Custom {
  background-color: var(--primary-light);
  color: var(--primary);
}

.InsertedEntityBody {
  display: flex;
  margin: .5rem 0;
  padding: .25rem 0;
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;
}

.IconContainer {
  display: flex;
  gap: 0.2rem;
  margin-left: auto;
}

.DeleteInsertedEntityIcon,
.EditInsertedEntityIcon {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  padding: 0;
  object-fit: contain;
}


.DisableBoxContainer {
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: not-allowed;
}