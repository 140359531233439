
.CarouselContainer {
    padding-top: 100px;
}

.title {

}

.carousel {

}

.TopContainer  {

}