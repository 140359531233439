.Wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.default-marker {
  background-color: blue; /* Colore di default del marker */
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.highlighted-marker {
  background-color: red; /* Colore del marker evidenziato */
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.CheckboxContainer {
  position: absolute;
  top: 100px;
  right: 10px;
  z-index: 1000;
  background: transparent;
  padding: 5px;
  border-radius: 5px;
}