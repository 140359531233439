.TopBar {
  width: 100%;
  height: var(--topbar-height);
  position: relative;
}

.VisualizationTopBar {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.NarrationDetailTopBar {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 1fr 1fr;
}

.AuthorTopBar {
  display: flex;
}

.Name {
  margin: auto auto auto 5rem;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: .25rem;
}

.InputContainer {
  margin: auto;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
}

.Input {
  padding: .75rem 3rem .75rem 1.5rem;
  border-radius: 1.25rem;
  width: 100%;
  display: flex;
  border: none;
  box-shadow: var(--box-shadow);
}

.IconButton {
  width: 4vh;
  height: 4vh;
  padding: .5rem;
  background-color: white;
  display: flex;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.Icon {
  flex: 1;
}

.Menu {
  position: absolute;
  right: 2.5vw;
  top: 8vh;
  background-color: #FFFFFF;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
  padding: 1rem;
  z-index: 100;
}

.Username {
  margin: 0;
  cursor: pointer;
  font-size: small;
  font-weight: bold;
  color: var(--text);
}

.Role {
  font-size: x-small;
  color: var(--text);
  margin: .25rem 0 0 0;
  text-transform: capitalize;
}

.Divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,0.25);
  margin-top: .5rem;
}

.Action {
  margin: .5rem 0 0 0;
  color: var(--text);
  font-size: small;
  cursor: pointer;
}

.Action::first-letter {
  text-transform: uppercase;
}

.Action:hover {
  background-color: var(--primary-light);
  font-weight: bold;
}

.Login {
  margin: 0;
  color: var(--text);
  font-size: small;
  cursor: pointer;
}

.Login::first-letter {
  text-transform: uppercase;
}

.Login:hover {
  background-color: var(--primary-light);
  font-weight: bold;
}

.DeleteSearch {
  position: absolute;
  right: calc(10% + 1rem);
  top: 50%;
  transform: translate(0, -50%);
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.Loader {
  margin: 0;
  padding: 0;
  position: absolute;
  right: calc(10% + 1rem);
  width: 1.5rem;
  height: 1.5rem;
  top: 22.5%;
  border: 2px solid var(--primary);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.InfoIcon {
  position: absolute;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  top: 22.5%;
  cursor: pointer;
}

.FilterIcon {
  position: absolute;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  top: 22.5%;
  cursor: pointer;
}

.FilterSearchArea {
  position: absolute;
  top: calc(100% + .5rem);
  right: calc(0% + 1.5rem/2);
  background-color: #FFF;
  box-shadow: var(--box-shadow);
  border-radius: .5rem;
  transform: translate(50%, 0);
  padding: 1rem;
}

.SearchAreaIntestation {
  display: flex;
  justify-content: space-between;
}

.SearchAreaTitle {
  margin: 0;
  font-size: x-small;
  color: var(--text);
  text-transform: uppercase;
}

.SelectDeselectAll {
  margin: 0;
  font-size: x-small;
  color: var(--text);
  text-transform: uppercase;
  cursor: pointer;
}

.SearchAreaContainer {
  margin-top: .5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.SearchChoiceContainer {
  margin: .125rem;
  display: flex;
}

.SearchChoiceContainer:nth-child(1),
.SearchChoiceContainer:nth-child(3n+1){
  margin-left: 0;
}

.SearchChoiceContainer:nth-child(3n){
  margin-right: 0;
}

.SearchChoiceCheckBox {
  margin-left: 0;
  cursor: pointer;
}

.SearchChoiceCheckBox:focus {
  outline: none !important;
}

.SearchChoiceLabel {
  cursor: pointer;
  margin: auto 0;
  font-size: x-small;
  color: var(--text);
  text-transform: uppercase;
}

.InputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.InputLabel {
  margin-right: 10px;
  width: 100px;
  font-size: small;
  color: var(--text);
  text-transform: uppercase;
}

.Input {
  flex: 1;
}

.Checkbox {
  margin-left: 10px;
}

.FilterByLocationContainer {
  display: flex;
  align-items: center;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}